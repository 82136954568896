import { Contentity } from "@/db/parade/contentities/schema"
import type { ContentArrayItem, ContentItem } from "@/schemas/content-item-extended.schema"
import type { SimplifiedContentItem } from "@/schemas/content-item-simplified.schema"

export function getLanguageIndex(
    contentItem: ContentItem | SimplifiedContentItem | Contentity,
    language: string
): number {
    const index = contentItem.content.findIndex((c) => c.language === language)
    return index === -1 ? 0 : index
}

export function getOriginLanguageIndex(
    contentItem: ContentItem | SimplifiedContentItem | Contentity
): number {
    if (!("origin" in contentItem)) return 0

    const originLanguageCode = contentItem.origin.language
    const index = contentItem.content.findIndex((c) => c.language === originLanguageCode)
    return index === -1 ? 0 : index
}

export function getContentForLanguage(
    contentItem: ContentItem | SimplifiedContentItem | Contentity | undefined,
    language?: string
): ContentArrayItem | undefined {
    if (!contentItem) {
        return undefined
    }
    const index = language ? getLanguageIndex(contentItem, language) : getOriginLanguageIndex(contentItem)
    const content = contentItem.content[index]

    return content as ContentArrayItem
}

export function getOriginLanguage(contentItem: ContentItem | Contentity): ContentArrayItem | undefined {
    return getContentForLanguage(contentItem)
}
