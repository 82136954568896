import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import Link from "next/link"
import { cn } from "@/lib/utils"

const buttonVariants = cva(
    "inline-flex items-center whitespace-nowrap justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-slate-400 disabled:pointer-events-none disabled:opacity-50 dark:focus-visible:ring-slate-800",
    {
        variants: {
            variant: {
                default:
                    "bg-tce-600 text-slate-50 shadow hover:bg-tce-700/90 dark:bg-slate-50 dark:text-slate-900 dark:hover:bg-slate-50/90",
                destructive:
                    "bg-red-500 text-slate-50 shadow-sm hover:bg-red-500/90 dark:bg-red-900 dark:text-red-50 dark:hover:bg-red-900/90",
                outline:
                    "border border-tce-500 dark:text-gray-300 bg-white shadow-sm hover:bg-tce-600 hover:text-white dark:border-tce-800 dark:bg-slate-950 dark:hover:bg-slate-800 dark:hover:text-tce-50",
                destructiveoutline:
                    "border border-red-500 bg-white shadow-sm hover:bg-red-500 hover:text-white dark:border-red-900 dark:bg-slate-950 dark:hover:bg-red-900/90 dark:text-slate-50",
                secondary:
                    "bg-slate-100 text-slate-900 shadow-sm hover:bg-slate-600/80 dark:bg-slate-800 dark:text-slate-50 dark:hover:bg-slate-800/80",
                orange: "bg-orange-700 text-white shadow-sm hover:bg-orange-600 dark:bg-orange-800 dark:hover:bg-orange-700",
                ghost: "hover:bg-slate-100 hover:text-slate-900 dark:hover:bg-slate-800 dark:hover:text-slate-50",
                link: "text-slate-900 underline-offset-4 hover:underline dark:text-slate-50",
                staff: "bg-violet-700 text-violet-50 shadow hover:bg-violet-600 dark:bg-violet-800 dark:hover:bg-violet-700",
                staffoutline:
                    "border border-violet-600 bg-white dark:text-violet-200 shadow-sm hover:bg-violet-100 hover:text-violet-900 dark:border-violet-400 dark:bg-slate-950 dark:hover:bg-violet-900 dark:hover:text-violet-50",
                staffdestructive:
                    "border border-violet-600 bg-gray-100 text-red-500 hover:text-white shadow-sm hover:bg-red-500/90 dark:border-violet-400 dark:bg-slate-950 dark:text-red-400 dark:hover:bg-red-900/90",
                green: "bg-green-600 text-white shadow-sm hover:bg-green-500/90 dark:bg-green-700 dark:hover:bg-green-600"
            },
            size: {
                default: "h-9 px-4 py-2",
                xs2: "h-4 rounded-sm px-1 text-xs",
                xs: "h-6 rounded-md px-2 text-xs",
                sm: "h-8 rounded-md px-3 text-xs",
                lg: "h-10 rounded-md px-8",
                xl: "h-12 rounded-md px-8 text-xl font-bold",
                icon: "h-9 w-9"
            }
        },
        defaultVariants: {
            variant: "default",
            size: "default"
        }
    }
)

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean
    href?: string
    scroll?: boolean
    prefetch?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        { className, variant, size, asChild = false, href, scroll = false, prefetch = false, ...props },
        ref
    ) => {
        const Comp = asChild ? Slot : "button"
        const buttonElement = (
            <Comp ref={ref} className={cn(buttonVariants({ variant, size, className }))} {...props} />
        )

        const disabled = props.disabled || props["aria-disabled"]

        // Conditionally wrap the button with a <Link> element if href is provided
        return href && !disabled ? (
            <Link href={href} scroll={scroll} prefetch={prefetch}>
                {buttonElement}
            </Link>
        ) : (
            <span>{buttonElement}</span>
        )
    }
)

Button.displayName = "Button"

export { Button, buttonVariants }
