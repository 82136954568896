export type FormatDateProps = {
    date: Date | string | number | undefined | null
    isLong?: boolean
    showTime?: boolean
    showExactTime?: boolean
    locale?: string
    className?: string
    isShort?: boolean
}

export const formatDate = ({
    date,
    isLong = false,
    showTime = false,
    showExactTime = false,
    locale,
    isShort = false
}: Omit<FormatDateProps, "className">): string => {
    if (!date) return ""

    const timeOptions: Intl.DateTimeFormatOptions = showExactTime
        ? { hour: "2-digit", minute: "2-digit", second: "2-digit" }
        : showTime
          ? { hour: "2-digit", minute: "2-digit" }
          : {}

    const optionsLong: Intl.DateTimeFormatOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        ...timeOptions
    }
    const optionsNormal: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "short",
        day: "numeric",
        ...timeOptions
    }
    const optionsShort: Intl.DateTimeFormatOptions = {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
        hour12: false,
        ...timeOptions
    }

    return new Date(date).toLocaleDateString(
        locale,
        isLong ? optionsLong : isShort ? optionsShort : optionsNormal
    )
}
