"use client"

import { useEffect, useState } from "react"
import { formatDate, type FormatDateProps } from "@/lib/format-date"

const DateFormatter = ({
    date,
    isLong = false,
    showTime = false,
    showExactTime = false,
    locale: propLocale,
    className,
    isShort = false
}: FormatDateProps) => {
    const [formattedDate, setFormattedDate] = useState("")
    const [locale, setLocale] = useState(propLocale)

    useEffect(() => {
        if (typeof globalThis !== "undefined" && !propLocale) {
            setLocale(globalThis.navigator.language)
        }

        if (propLocale) {
            setLocale(propLocale)
        }
    }, [propLocale])

    useEffect(() => {
        setFormattedDate(formatDate({ date, isLong, showTime, showExactTime, locale, isShort }))
    }, [date, isLong, showTime, showExactTime, locale, isShort])

    const dateTimeFormat = date ? new Date(date).toISOString().split("T")[0] : ""

    return (
        <time data-testid="time-element" className={className} dateTime={dateTimeFormat}>
            {formattedDate}
        </time>
    )
}

export default DateFormatter
