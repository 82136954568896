"use client"

import Image from "next/image"
import { getCopyrightString } from "@/lib/image-copyright"
import type { Asset } from "@/schemas/content-item-extended.schema"
import { useEffect, useState } from "react"
import { cn } from "@/lib/utils"
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogClose,
    DialogDescription,
    DialogHeader
} from "@/components/ui/dialog"
import { Grid2x2, Layers, Images, X } from "lucide-react"

interface ContentItemImageProps {
    image?: Asset | undefined
    showResolution?: boolean
    imageHeight?: string
    isClickable?: boolean
    showMultipleIcon?: boolean
    priority?: boolean
    unoptimized?: boolean
}

export function ContentItemImage({
    image,
    showResolution = false,
    imageHeight = "h-48",
    isClickable = false,
    showMultipleIcon = false,
    priority = false,
    unoptimized = true
}: ContentItemImageProps) {
    const [resolution, setResolution] = useState<{
        width: number
        height: number
    } | null>(null)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (!image || !image.uri) return

        const img = new globalThis.Image()
        img.addEventListener("load", () => {
            setResolution({ width: img.width, height: img.height })
        })
        img.src = image.uri
    }, [image])

    if (!image) return <div className="h-48 w-full bg-gray-200 object-cover" />

    const copyright = image.copyright ? getCopyrightString(image.copyright) : undefined

    const imageContainer = (
        <div
            className={cn("relative w-full overflow-hidden", isClickable && "cursor-pointer")}
            onClick={isClickable ? () => setIsOpen(true) : undefined}
        >
            <Image
                unoptimized={unoptimized}
                className={cn(
                    "searchresultimage w-full transform bg-gray-200 object-cover transition-transform duration-200 ease-in-out hover:scale-105",
                    imageHeight
                )}
                src={image.uri}
                alt=""
                width={300}
                height={200}
                sizes="25vw"
                priority={priority}
            />
            {copyright && (
                <div
                    className={`absolute bottom-0 left-0 bg-gray-700 px-1 text-gray-100 opacity-75 ${copyright.length > 50 ? "text-xs" : "text-sm"}`}
                >
                    {copyright}
                </div>
            )}
            {resolution && showResolution && (
                <div className="absolute top-0 left-0 bg-gray-700 px-1 text-gray-100 opacity-75 text-xs">
                    {resolution.width}x{resolution.height}
                </div>
            )}
            {showMultipleIcon && (
                <div className="absolute bottom-2 right-2 bg-gray-700/75 rounded-full p-1">
                    <Images className="h-4 w-4 text-white" />
                </div>
            )}
        </div>
    )

    if (!isClickable) return imageContainer

    return (
        <>
            {imageContainer}
            <Dialog open={isOpen} onOpenChange={setIsOpen}>
                <DialogContent className="max-w-[95vw] max-h-[95vh] w-fit h-fit p-0 overflow-hidden">
                    <DialogHeader className="sr-only">
                        <DialogTitle className="sr-only"></DialogTitle>
                        <DialogDescription className="sr-only"></DialogDescription>
                    </DialogHeader>
                    <DialogClose className="absolute right-3 top-3 z-20 rounded-full bg-black p-2 hover:bg-tce-600 border border-tce-600">
                        <X className="h-4 w-4 text-white" />
                        <span className="sr-only">Close</span>
                    </DialogClose>
                    <div className="relative">
                        <Image
                            unoptimized={unoptimized}
                            className="max-w-[95vw] max-h-[95vh] w-auto h-auto object-contain rounded-lg"
                            src={image.uri}
                            alt=""
                            width={1200}
                            height={800}
                            sizes="90vw"
                        />
                        {copyright && (
                            <div
                                className={`absolute bottom-0 right-0 bg-gray-700/75 px-1 text-gray-100 ${
                                    copyright.length > 50 ? "text-xs" : "text-sm"
                                }`}
                            >
                                {copyright}
                            </div>
                        )}
                        {resolution && (
                            <div className="absolute top-1 left-1 bg-gray-700 px-1 text-gray-100 opacity-75 text-xs rounded-sm">
                                {resolution.width}x{resolution.height}
                            </div>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
