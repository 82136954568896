export function getCopyrightString(initialCopyright: string) {
    let copyright = initialCopyright.trim()

    // Remove 'all rights reserved' from the copyright string, case insensitive:
    const allRightsReservedRegex = /all rights reserved/giu
    copyright = copyright.replace(allRightsReservedRegex, "")

    // Trim again to remove leading/trailing whitespace that may have been left after removing 'all rights reserved'
    copyright = copyright.trim()

    // Add copyright symbol if not already present somewhere in the string:
    if (copyright.includes("©")) {
        // Ensure a space follows the copyright symbol if it's already in the string:
        copyright = copyright.replace(/©(?!\s)/gu, "© ")
    } else {
        copyright = `© ${copyright}`
    }

    // Replace "Getty Images" with just "Getty" to save space:
    const gettyImagesRegex = /Getty Images/giu
    copyright = copyright.replace(gettyImagesRegex, "Getty")

    return copyright
}
