import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

// https://www.youtube.com/watch?v=re2JFITR7TI
export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function htmlToPlainText(html: string | string[] | null | undefined): string {
    if (!html) return ""
    if (Array.isArray(html)) html = html.join("")
    return html.replace(/<[^>]*>?/gm, "").trim()
}

export const truncateText = (text: string | undefined, maxLength: number) => {
    if (!text) return ""
    if (text.length <= maxLength) return text

    // Ensures the string ends in a word.
    const truncatedText = text.slice(0, maxLength)
    return `${truncatedText.slice(0, truncatedText.lastIndexOf(" "))}...`
}

export function isStaffEmail(email: string | null | undefined): boolean {
    if (!email) {
        return false
    }

    const validDomains = ["tce.exchange", "ibuildings.com", "ibuildings.nl", "ibuildings.be"]
    const domainRegex = /@([\w.-]+)/
    const match = domainRegex.exec(email)
    const domain = match ? match[1]! : ""

    return validDomains.includes(domain)
}

export const newUUID = () => {
    return crypto.randomUUID()
}

export async function sleep(seconds: number) {
    await new Promise((resolve) => setTimeout(resolve, seconds * 1000))
}
